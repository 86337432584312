import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { get, last, compact } from "lodash";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";

import { ContentBlock } from "../../../components/Blocks";
import Contents from "../../../components/Contents";
import Pages from "../../../components/Pages";
import Breadcrumbs from "../../../components/Layout/Breadcrumbs";
import { makeUrl, removeEmptyKeys, removePreloader } from "../../../helpers";
import { Share } from "../../../widgets";
import * as TargetBanner from "../../../components/TargetBanner";
import { TEXT_PAGES, TEXT_PAGES_MAX_UPDATE } from "../../../queries/queries.graphql";
import EmptyData from "../../../components/EmptyData";
import { useThemeContext } from "../../../components/Layout/ThemeContext";

import "./TextPage.scss";

export const query = graphql`
	query textPagesDataQuery($slug: String!) {
		hasura {
			...TextPages
			...Banners
		}
	}
`;

export default function TextPage({ data, pageContext, location, previewData }) {
	const { theme } = useThemeContext();

	const [textPages, setTextPages] = useState(get(data, "hasura.text_pages[0]", {}));

	const path = get(previewData, "slug", "") || last(compact(get(location, "pathname", "").split("/")));
	const maxUpdated = new Date(get(data, "hasura.text_pages_aggregate.aggregate.max.updated_at", new Date()));

	const textPagesOptions = {
		variables: {
			location: path,
		},
		fetchPolicy: "cache-and-network",
		ssr: false,
	};

	const { loading: maxUpdateLoading, data: maxUpdateData, error: maxUpdateError } = useQuery(TEXT_PAGES_MAX_UPDATE, textPagesOptions);

	const [loadTextPages, { called: textPagesCalled, loading: textPagesLoading, error: textPagesError, data: textPagesData }] = useLazyQuery(TEXT_PAGES, textPagesOptions);

	useEffect(() => {
		removePreloader();
	}, []);

	useEffect(() => {
		if (maxUpdateData && !maxUpdateError) {
			const currentMaxUpdated = new Date(get(maxUpdateData, "text_pages_aggregate.aggregate.max.updated_at", new Date()));

			if (+currentMaxUpdated !== +maxUpdated) {
				loadTextPages();
			} else {
				removePreloader();
			}
		} else if (maxUpdateError) {
			console.error("Invalid load TextPagesMaxUpdate", { maxUpdateError });
			removePreloader();
		}
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (textPagesData && !textPagesError) {
			setTextPages(get(textPagesData, "text_pages[0]", textPages));
			removePreloader(textPagesCalled);
		} else if (textPagesError) {
			console.error("Invalid load TextPagesData", { textPagesError });
			removePreloader();
		}
	}, [textPagesLoading]);

	const banners = get(data, "hasura.banners", []);
	const url = makeUrl.textPage(textPages);
	const imageSrc = get(textPages, "main_image.src", "");
	const content_blocks = get(textPages, "content_blocks", "");
	const page_title_full = get(textPages, "page_title_full", "");
	const bannerBlock = banners.filter(banner => get(banner, "params.enabled", false) && get(banner, "params.locations", "").match(new RegExp(get(pageContext, "slug", "")))).map(banner => {
		return React.createElement(TargetBanner[get(banner, "params.type", "")] || TargetBanner.BannerImg, {
			...banner,
		});
	});
	const updated_at = get(textPages, "updated_at", new Date());

	return textPages ? (
		<Pages entity={textPages} url={url} >
			{!!bannerBlock.length && <ContentBlock key={"banner"}>
				<div className={"banner__main-block d-noscript-none"}>{bannerBlock}</div>
			</ContentBlock>}
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs currentLabel={page_title_full} />
				</div>
			</ContentBlock>
			<ContentBlock
				key={"media-content"}
				mount={content_blocks && !!content_blocks.length}
				className={"text-page"}
			>
				<div className={"container"}>
					<div className={"h1 text-page_title"}>
						<h1 itemProp="headline"
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.h1,
								color: theme.color.text,
							})}>
							{page_title_full}
						</h1>
					</div>
					<Contents items={content_blocks} date={updated_at}/>
				</div>
			</ContentBlock>
			<div className={"container"}>
				<Share url={url} pageTitleFull={page_title_full} imageSrc={imageSrc} />
			</div>
		</Pages>
	) : (
		<EmptyData />
	);
}

TextPage.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
	location: PropTypes.object,
	previewData: PropTypes.object,
};

TextPage.defaultProps = {
	data: {},
	pageContext: {},
	location: {},
	previewData: {},
};
